import React from 'react'
import image from  "../../assets/images/error/01.png"
import { Link } from 'react-router-dom'

function Partner404Page() {
  return (
    <div class="wrapper">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-sm-12 text-center">
                <div class="iq-error">
                    <h1>400</h1>
                    <h4 class="mb-0">Oops! This Page is Not Found.</h4>
                    <p>The requested page dose not exist.</p>
                    <Link to='/partner' class="btn btn-primary  mt-n4" style={{cursor:"pointer"}}><i class="ri-home-4-line"></i>Back to Home</Link>
                    <img src={image} class="img-fluid iq-error-img " alt=""/>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Partner404Page