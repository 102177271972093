import React, { useEffect, useState ,useRef} from 'react'
import map from '../../asserts/img/real-estate/single/map.jpg'
import owneravatar from '../../asserts/img/avatars/14.png'
import SigninModal from '../../components/landingcomponents/signinModal'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useAddReviewMutation ,useGetReviewsMutation,useGetPropertiesDetailUserMutation,useBookPropertyMutation,useCancelBookingMutation} from '../../features/property/propertySlice'
import { selectCurrentToken ,selectUserData} from '../../features/auth/authSlice'
import { useSelector } from 'react-redux'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Link, useLocation, useParams } from 'react-router-dom'
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { differenceInDays, differenceInWeeks, differenceInMonths,addDays, format, parseISO, parse } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import { Calendar } from 'react-date-range';




function PropertyDetailPage() {


  const [getpropertydetail,{isLoading}]=useGetPropertiesDetailUserMutation()
  const [propertydetail,setPropertyDetail]=useState()
  const [showmodal,setshowmodal]=useState(false)
const [showbackdrop,setshowbackdrop]=useState(false)
const [ratingnumber,setRatingNumber]=useState(0)
const [addreview,{isLoading:isAddingReview}]=useAddReviewMutation()
const [getreviews,{isLoading:isLoadingReview}]=useGetReviewsMutation()
const [bookproperty,{isLoading:isLoadingBooking}]=useBookPropertyMutation()
const [reviews,setReviews]=useState()
const [reviewscount,setReviewscount]=useState(0)
const descriptionref=useRef()
const [showmore,setShowMore]=useState(false)
const [showtotal,setShowTotal]=useState(false)
const [total,setTotal]=useState(false)
const [imagelength,setImageLength]=useState()
const {id}=useParams()
const dropdownRef=useRef(null)
const [show,setShow]=useState(false)

const handleClickOutside = (event) => {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setShow(false);
  }
};

const [calculatestring,setCalculatestring]=useState("")
const [showMonths, setShowMonths] = useState(window.innerWidth <= 660 ? 1 : 2);
const [showbookedsuccess,setShowBookedSuccess]=useState(false)
const [serviceFee,setServiceFee]=useState()
useEffect(() => {
  const handleResize = () => {
    setShowMonths(window.innerWidth <= 660 ? 1 : 2);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);






const getDatesInRange = (startDate, endDate) => {
  let dates = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    dates.push(format(currentDate, 'yyyy-MM-dd'));
    currentDate = addDays(currentDate, 1);
  }

  return dates;
};

const [occupiedDates, setOccupiedDates] = useState([]);

const addOneDay = (date,min_days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + min_days);
  return newDate;
};


const [bookingData,setBookingData]=useState({
  "property": "",
  "price": "",
  "check_in": "",
  "check_out":"",
  "total_amount": ""
})




const durationRef=useRef()
const totalpeopleRef=useRef()
const formref=useRef()
const [duration, setDuration] = useState({ days: 0, weeks: 0, months: 0 });

const calculateDuration = (checkIn, checkOut) => {
  if (checkIn && checkOut) {
    const days = differenceInDays(checkOut, checkIn);
    const weeks = differenceInWeeks(checkOut, checkIn);
    const months = differenceInMonths(checkOut, checkIn);
    setDuration({ days, weeks, months });
  }
 
};

const handleCheckinDateChange = (date) => {
  handletotalcalculate()
  setBookingData((prevData) => ({
    ...prevData,
    check_in: date,
  }));

  calculateDuration(date, bookingData.check_out);
 
};

const handletotalcalculate=(e)=>{

  let discount=0

  setShowTotal(true)
  calculateDuration(bookingData.check_in, bookingData.check_out);
  if  (parseInt(durationRef.current.value)>0 && parseInt(totalpeopleRef.current.value)>0){


if (parseInt(durationRef.current.value)>=30 ){
 discount=parseInt(propertydetail.pricing[0].discount_one_month)
  

}
else if(parseInt(durationRef.current.value)>=14){
  discount=parseInt(propertydetail.pricing[0].discount_two_week)

}
else if(parseInt(durationRef.current.value)>=7){
  discount=parseInt(propertydetail.pricing[0].discount_one_week)
}



setTotal(((parseInt(propertydetail.pricing[0].price )*parseInt(durationRef.current.value))+ calculateServiceFee())-(parseInt(discount)))

  }
  else{
    setShowTotal(false)

  }

}


const handleCheckoutDateChange = (date) => {
  handletotalcalculate()
  setBookingData((prevData) => ({
    ...prevData,
    check_out: date,
  }));
  calculateDuration(bookingData.check_in, date);
  
};


const getMinDate = (checkInDate) => {
  const minDate = new Date(checkInDate);
  minDate.setDate(minDate.getDate() + 1);
  return minDate.toISOString().slice(0, 16).replace('T', ' ');
};


  function calculateCharge(service, duration) {
      if (duration >= 30) {
          return service.charge_per_month ; // Monthly charge
      } else if (duration >= 7) {
          return service.charge_per_week  // Weekly charge
      } else {
          return service.charge_per_day ; // Daily charge
      }
  }



  // Function to calculate service fee based on duration
function calculateServiceFee() {
  const duration = parseInt(durationRef.current.value);
  let totalServiceFee = 0;
   propertydetail.service_fee.forEach(service => {
    totalServiceFee += calculateCharge(service, duration);
  });
  return totalServiceFee;
}

const handleBookProperty = async (e) => {
  
  e.preventDefault();



  // Check form validity
  const isValid = formref.current.checkValidity();

  if (isValid) {
  

    try {
      // Use updated bookingData state here
     
   

      console.log({
        
        "property":propertydetail.id ,
        "check_in": bookingData.check_in,
        "check_out": bookingData.check_out,
        "price":propertydetail.pricing[0].id,
        "total_amount":parseInt(total)
      
    })

    const response = await bookproperty({
        
      "property":propertydetail.id ,
      "check_in": bookingData.check_in,
      "check_out": bookingData.check_out,
      "price":propertydetail.pricing[0].id,
      "total_amount":parseInt(total)
    
  }).unwrap();
  console.log(response)
      toast("Enquiry Submitted")
      if (durationRef.current) {
        durationRef.current.value = '';
      }
      if(totalpeopleRef.current){
        totalpeopleRef.current.value = '';
      }

      setBookingData(
        {
          property: "",
          price: "",
          check_in: "",
          check_out: "",
          total_amount: "",
        }
      )
      setDuration({
        days: 0,
        weeks: 0,
        months: 0,
      })
    } catch (error) {
      console.error("API Error:", error); // Log API error
    }
  } else {
    // If form is not valid, add validation classes
    formref.current.classList.add("was-validated");
  }
};


const toggleModal = () => {
  setshowmodal(!showmodal)
  setshowbackdrop(!showbackdrop)
};


const  token =useSelector(selectCurrentToken)
const userdata=useSelector(selectUserData)

  const handleGetPropertyDetail=async()=>{

    try{
    const property_id=id;
    const response=await getpropertydetail(property_id);
    console.log('detail',response.data);
    setPropertyDetail(response.data)

    let allOccupiedDates = [];

    response.data.booking.forEach(entry => {
      const checkInDate = parseISO(entry.check_in);
      const checkOutDate = parseISO(entry.check_out);
      const rangeDates = getDatesInRange(checkInDate, checkOutDate);
      allOccupiedDates = allOccupiedDates.concat(rangeDates);
    });

    console.log(allOccupiedDates)
    setOccupiedDates(allOccupiedDates);
    setImageLength(response.data.other_files.slice(2,7).length)
    console.log(response.data.other_files.slice(2,7).length)
    


    }
    catch(e){
      console.log(e)
    }
    
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };
  
  const [nextReview,setNextReview]=useState()
  const [previousReview,setPreviousReview]=useState()
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState()





  const handleGetReviews=async(pageparam)=>{
    try {
      const param=
      {
        page:pageparam,
        property_id:id
      }
      
      
      const response = await getreviews(param);
      console.log('reviews',response)
      setReviews(response.data.results)
      console.log("next" ,response.data.next)
      setNextReview(response.data.next)
      setPreviousReview(response.data.previous)
      setReviewscount(response.data.count)
      setPageCount(0)
      setPageCount(Math.ceil(response.data.count / 10))
      console.log("page count: " ,pageCount)


    }catch(err){
      console.log(err)
    }
  }


  const handlePagination=(page)=>{
    setPage(page);
    handleGetReviews(page);
  }

  useEffect(()=>{
    handleGetPropertyDetail();
    handleGetReviews(page)
 
  },[])






  const handleAddReview=async(e)=>{
    try {


      e.preventDefault()
      const description=descriptionref.current.value
      const rating=ratingnumber
      const user_id=userdata.id
     
  
  
  const body ={
    "description":description,
    "rating":rating,
    "user":user_id,
    "property":id
  }
  
  const response= await addreview(body).unwrap()
  setshowmodal(false)
  handleGetReviews()



    }catch(e){
      console.log(e)
    }


  }

  const location=useLocation()
  const currentUrl = window.location.origin + location.pathname;


  const shareLinks = [
    {
      href: `https://www.facebook.comsharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
      iconClass: 'fi-facebook fs-base opacity-75 me-2',
      text: 'Facebook',
    },
    {
      href: `https://twitter.comintent/tweet?text=Check%20this%20out!&url=${encodeURIComponent(currentUrl)}`,
      iconClass: 'fi-twitter fs-base opacity-75 me-2',
      text: 'Twitter',
    },
    {
      href: `https://wa.me/?text=${encodeURIComponent(currentUrl)}`,
      iconClass: 'fi-whatsapp fs-base opacity-75 me-2',
      text: 'WhatsApp',
    },
    {
      href: `https://telegram.me/share/url?url=${encodeURIComponent(currentUrl)}&text=Check%20this%20out!`,
      iconClass: 'fi-telegram fs-base opacity-75 me-2',
      text: 'Telegram',
    },
  ];

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    
    <div>
    <section className="container pt-5 mt-5">
    <ToastContainer
  position="top-right"
  autoClose={2000}
  hideProgressBar={true}
  type='success'

 
/>

        <nav className="mb-3 pt-md-3" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="real-estate-home-v1.html">Home</a></li>
            <li className="breadcrumb-item"><Link to='/properties' >Properties</Link></li>
            <li className="breadcrumb-item active" aria-current="page">
            {
          isLoading? <Skeleton style={{width:"100px",height:"8px"}}/>:
          propertydetail && propertydetail.title
        }
            </li>
          </ol>
        </nav>
        <h1 className="h2 mb-2">
        {
          isLoading? <Skeleton style={{width:"200px",height:"20px"}}/>:
          propertydetail && propertydetail.title
        }
        </h1>
       
        
        <div className="d-flex justify-content-between align-items-center">
          <ul className="d-flex mb-4 list-unstyled">
            <li className="me-3 pe-3 border-end d-flex justify-content-center align-items-center">
            
            <b className="me-1" style={{marginBottom:"4px"}}>   {
          isLoading? <Skeleton style={{width:"10px",height:"10px"}}/>:
          propertydetail && <span  > {propertydetail.bedroom}</span>
        }</b><i className="fi-bed mt-n1   lead align-middle text-muted"></i>
        
        </li>
            <li className="me-3 pe-3 border-end d-flex justify-content-center align-items-center">
              <b className="me-1" style={{marginBottom:"4px"}}>   {
          isLoading? <Skeleton style={{width:"10px",height:"10px"}}/>:
          propertydetail && <span  > {propertydetail.bathroom}</span>
        }</b><i className="fi-bath mt-n1 lead align-middle text-muted"></i></li>
            <li className="me-3 pe-3 border-end d-flex justify-content-center align-items-center">
            <b className="me-1" style={{marginBottom:"4px"}}>   {
          isLoading? <Skeleton style={{width:"10px",height:"10px"}}/>:
          propertydetail && <span  > {propertydetail.parking_spot}</span>
        }</b><i className="fi-car mt-n1 lead align-middle text-muted"></i></li>
            
          </ul>
          <div className="text-nowrap">
            <div className="dropdown d-inline-block" data-bs-toggle="tooltip" title="Share">
              <button  onClick={()=>{setShow(!show)}} className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle ms-2 mb-2" type="button" data-bs-toggle="dropdown"><i className="fi-share"></i></button>
              
              <div ref={dropdownRef} className={`dropdown-menu dropdown-menu-end my-1  ${show?"show":""}`} style={{marginLeft:"-100px"}}>
      {shareLinks.map((link, index) => (
        <a key={index} href={link.href} className="dropdown-item" target="_blank" rel="noopener noreferrer">
          <i className={link.iconClass}></i>
          {link.text}
        </a>
      ))}
    </div>
            </div>
          </div>
        </div>
      </section>
      {
        isLoading?
       
        <section className="container overflow-auto mb-4 pb-3">
        <PhotoProvider
        speed={() => 800}
  easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
      >
        <div className="row g-2 g-md-3 gallery" data-thumbnails="true" style={{ minWidth: '30rem' }}>
          <div className="col-8">
           
            <div >
                <div  className="gallery-item rounded rounded-md-3" >
              <Skeleton 
                style={{ height: '600px', objectFit: 'cover' }} 
                className="rounded rounded-md-3" />
              </div>
              </div >
           
          </div>
          <div className="col-4">
          <div className="gallery-item rounded rounded-md-3 mb-2 mb-md-3" >
              <Skeleton style={{height: '290px'}}/>
            </div>
            
            
           
                  
                           <div className="gallery-item rounded rounded-md-3 mb-2 mb-md-3" >
              <Skeleton style={{height: '290px'}}/>
            </div>
            
            
            
            </div>
          
          <div className="col-12">
            <div className=" row row-cols-4  g-2 g-md-3">
        
          
          
               
            <div className="col">          
             <div
              className={`gallery-item rounded rounded-md-3 mb-2 mb-md-3`} >
              <Skeleton style={{height: '200px'}}/>
                              </div>
            
            </div>
            
            <div className="col">          
             <div
              className={`gallery-item rounded rounded-md-3 mb-2 mb-md-3`} >
              <Skeleton style={{height: '200px'}}/>
                              </div>
            
            </div>
            
            <div className="col">          
             <div
              className={`gallery-item rounded rounded-md-3 mb-2 mb-md-3`} >
              <Skeleton style={{height: '200px'}}/>
                              </div>
            
            </div>
            
            <div className="col">          
             <div
              className={`gallery-item rounded rounded-md-3 mb-2 mb-md-3`} >
              <Skeleton style={{height: '200px'}}/>
                              </div>
            
            </div>
      
              
              </div>

            </div>
          
        </div>
        </PhotoProvider>
     
    </section>
        :
        propertydetail&&
        <section className="container overflow-auto mb-4 pb-3">
        <PhotoProvider
        speed={() => 800}
  easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
      >
        <div className="row g-2 g-md-3 gallery" data-thumbnails="true" style={{ minWidth: '30rem' }}>
          <div className="col-8">
           
            <PhotoView     
             src={`https://api.shortstaybuddy.com${propertydetail.files}`}>
                <div  className="gallery-item rounded rounded-md-3" >
              <img 
                style={{ height: '600px', objectFit: 'cover' }} 
                className="rounded rounded-md-3" 
                src={`https://api.shortstaybuddy.com${propertydetail.files}`}
                      alt="Gallery thumbnail" 
              />
              </div>
              </PhotoView>
           
          </div>
          <div className="col-4">
          {
            
            propertydetail && propertydetail.other_files[0]&&
            <PhotoView     
             src={`https://api.shortstaybuddy.com${propertydetail.other_files[0].files}`}>
             <div
              className="gallery-item rounded rounded-md-3 mb-2 mb-md-3" 
             
            >
              <img 
                style={{ height: '290px', objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com${propertydetail.other_files[0].files}`}
                alt="Gallery thumbnail" 
              />
            </div>
            </PhotoView>
          }
            
            {
              propertydetail && propertydetail.other_files[1]&& 
              <PhotoView     
             src={`https://api.shortstaybuddy.com${propertydetail.other_files[1].files}`}>
             <div
              className="gallery-item rounded rounded-md-3 mb-2 mb-md-3" 
             
            >
              <img 
                style={{ height: '290px', objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com${propertydetail.other_files[1].files}`}
                alt="Gallery thumbnail" 
              />
            </div>
            </PhotoView>
            }
           
          </div>
          <div className="col-12">
            <div className=" row row-cols-4  g-2 g-md-3">
            {
            propertydetail && 
        propertydetail.other_files[2]&&
        propertydetail.other_files.slice(2,7).map((file,index)=>{

          return (
          

            <>
            <div className="col">

            {
              index>3?
              <PhotoView     
             src={`https://api.shortstaybuddy.com${file.files}`}>
             <div
              className={`gallery-item ${index >=3 &&imagelength>4?"more-item ":""} rounded rounded-md-3 mb-2 mb-md-3`} >
              <img 
                style={{ height: '200px',display:"none", objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com${file.files}`}
                alt="Gallery thumbnail" 
              />
                           

            </div>
            
            </PhotoView>
             :
             
              <PhotoView     
             src={`https://api.shortstaybuddy.com${file.files}`}>
             <div
              className={`gallery-item ${index >=3 &&imagelength>4?"more-item ":""} rounded rounded-md-3 mb-2 mb-md-3`} >
              <img 
                style={{ height: '200px', objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com${file.files}`}
                alt="Gallery thumbnail" 
              />
                           

              
              {
                index >=3 &&imagelength>4?
                <span class="gallery-item-caption fs-base" style={{cursor:"pointer"}}>
                
                +{imagelength-4} <span class='d-none d-md-inline'>photos</span>
                
                </span>
                :""
              }
            </div>
            
            </PhotoView>
            }
               
      
              
              </div>

            </>
         ) })
          
          }
         
            </div>
          </div>
        </div>
        </PhotoProvider>
     
    </section>
      }



      <section className="container mb-5 pb-1">
        <div className="row">
          <div className="col-md-7 mb-md-0 mb-4">

          <h4 className="h5 mb-0"><span className="fs-4">{ propertydetail&&propertydetail.pricing[0].currency +" "+  propertydetail.pricing[0].price}&nbsp;</span><span className="fs-base fw-normal text-body">/day</span></h4>
          <span className="fs-base fw-normal text-body">Min days /{propertydetail&&propertydetail.pricing[0].max_days_allowed} days</span>


     

          <h2 className="h3 mb-4 pb-4 border-bottom">
			
					
				
				</h2>

            <div className="mb-4 pb-md-3">
              <h3 className="h4">Overview</h3>
              <p className="mb-1 "  style={{wordWrap:'break-word'}}>

                {
                  isLoading?<Skeleton style={{width: '300px', height: "200px"}} />:
                  propertydetail&&propertydetail.description.length >300?
                  <>
                  {propertydetail&&propertydetail.description.slice(0,300)}<br></br>
                  {
                    showmore?"":
                    <a className="collapse-label collapsed" onClick={()=>{setShowMore(true)}} style={{cursor:"pointer"}}>show more</a>

                  }

                  <div className={`collapse ${showmore?"show":""}`} id="seeMoreOverview" >
                <p className="mb-1">
                {propertydetail&&propertydetail.description.slice(300,propertydetail.description.length)}
                <br>

                </br>
                    <a className="collapse-label collapsed" onClick={()=>{setShowMore(false)}} style={{cursor:"pointer"}}>show less</a>

                </p>
              </div>
                  </>
    :
    propertydetail&&propertydetail.description
                }
              </p>
       
            </div>
            
            <div className="mb-4 pb-md-3">
              <h3 className="h4">Property Details</h3>
              <ul className="list-unstyled mb-0">
                      <li className="d-flex"><b>Category: </b>
          {
            isLoading ?
            <Skeleton style={{width: '200px', height: "15px", marginLeft: "5px"}} /> :
            propertydetail && propertydetail.category.map((category, index, arr) => {
              // Check if it's the last element
              const separator = index === arr.length - 1 ? '.' : ', ';
              return category.name + separator;
            })
          }
        </li>

                
                <li><b>Bedrooms: </b>{propertydetail&&propertydetail.bedroom}</li>
                <li><b>Bathrooms: </b>{propertydetail&&propertydetail.bathroom}</li>
              <li><b>Parking places: </b>{propertydetail&&propertydetail.parking_spot}</li>
              <li><b>Max people allowed: </b> {propertydetail&&propertydetail.mi}</li>

              
              <li><b>Parties allowed: </b> {propertydetail&&propertydetail.is_party_inclusive==="true"?"Yes":"No"}</li>


                <li><b>Privilege:</b>{propertydetail && propertydetail.privilege.map((privilege, index, arr) => {
              // Check if it's the last element
              const separator = index === arr.length - 1 ? '.' : ', ';
              return privilege.name +"allowed"+ separator;
            })
          }</li>
              </ul>
            </div>
        

        
            <div className="mb-4 pb-md-3">
              <h3 className="h4">Amenities</h3>
              <ul className="list-unstyled row row-cols-lg-3 row-cols-md-2 row-cols-1 gy-1 mb-1 text-nowrap">
              {
                propertydetail && propertydetail.amenity.map((amenity,e)=>{
                  return (
                    <li className="col"><i className="fi-snowflake mt-n1 me-2 fs-lg align-middle" key={e} ></i>{amenity.name}</li>


                  )
                })
              }
               
              </ul>
            </div>


            
            <div className="mb-lg-5 mb-md-4 pb-lg-2 py-4 border-top">
              <ul className="d-flex mb-4 list-unstyled fs-sm">
             
               
              </ul>
            </div>
           

        

         
         <div className="mb-4">
         <h3>Occupied Dates</h3>

         <Calendar
        date={new Date()}
        color="#0d6efd"
        months={2}
        direction="horizontal"
        disabledDates={occupiedDates}
      />
    
         </div>

{ /* add review modal */}

<div className={`backdrop ${showmodal ? 'show' : 'fade'}`} >

<div className="modal-dialog modal-dialog-centered" role="document">
		<div className="modal-content">
			<div className="modal-header d-block position-relative border-0 pb-0 px-sm-5 px-4">
				<h3 className="modal-title mt-4 text-center">Leave a review</h3>
				<button onClick={()=>{setshowmodal(false)}}  className="btn-close position-absolute top-0 end-0 mt-3 me-3" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div className="modal-body px-sm-5 px-4">
								<form className="needs-validation"  onSubmit={handleAddReview} >
							
                <div className="mb-4">
						<label className="form-label" for="review-text">rating <span className="text-danger">*</span></label>
            <br/>

            <span className="star-rating" >
																															<i onClick={()=>{setRatingNumber(1)}} className={`star-rating-icon  ${ratingnumber>0?'fi-star-filled text-warning':'fi-star' }`} style={{fontSize:"25px",cursor:"pointer"}}></i>
																					<i  onClick={()=>{setRatingNumber(2)}}  className={`star-rating-icon  ${ratingnumber>1?'fi-star-filled text-warning':'fi-star' }`}  style={{fontSize:"25px",cursor:"pointer"}}></i>
																					<i  onClick={()=>{setRatingNumber(3)}}  className={`star-rating-icon  ${ratingnumber>2?'fi-star-filled text-warning':'fi-star' }`} style={{fontSize:"25px",cursor:"pointer"}}></i>
																					<i   onClick={()=>{setRatingNumber(4)}}  className={`star-rating-icon  ${ratingnumber>3?'fi-star-filled text-warning':'fi-star' }`} style={{fontSize:"25px",cursor:"pointer"}}></i>
																					<i  onClick={()=>{setRatingNumber(5)}}   className={`star-rating-icon  ${ratingnumber>4?'fi-star-filled text-warning':'fi-star' }`} style={{fontSize:"25px",cursor:"pointer"}}></i>
																				
									</span>
            </div>
				


					<div className="mb-4">
						<label className="form-label" for="review-text">Review <span className="text-danger">*</span></label>
						<textarea ref={descriptionref} className="form-control" id="review-text" rows="5" placeholder="Your review message" ></textarea>

											</div>
                      {
                        isAddingReview?
                        <button  className="btn btn-primary d-block w-100 mb-4" type="submit">
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        submitting...
                        </button>

:
<button  className="btn btn-primary d-block w-100 mb-4" type="submit">Submit a review</button>


                      }
				</form>
			</div>
		</div>
	</div>
</div>


           
           
           
            <div className="mb-4 pb-4 border-bottom">
              <h3 className="h4 pb-3"> {reviewscount>1 || reviewscount===0 ? '('+ reviewscount+' reviews)': '('+reviewscount+' review)'  } </h3>
              <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch justify-content-between">
              


              {
              token? 
              <span  onClick={()=>{setshowmodal(true)}} className="btn btn-outline-primary mb-sm-0 mb-3" href="#modal-review" data-bs-toggle="modal"><i className="fi-edit me-1"></i>Add review</span>

                  :
              <span onClick={toggleModal} to="/login" className="btn btn-outline-primary mb-sm-0 mb-3" href="#signin-modal" data-bs-toggle="modal">
					<i className="fi-user me-1"></i>
					Login to review property
				</span>
              
              }

                <div className="d-flex align-items-center ms-sm-4">
                {
                  reviewscount &&reviewscount>1 ?
                  <>
                  <label className="me-2 pe-1 text-nowrap" for="reviews-sorting"><i className="fi-arrows-sort text-muted mt-n1 me-2"></i>Sort by:</label>
                  <select className="form-select" id="reviews-sorting">
                    <option>Newest</option>
                    <option>Oldest</option>
                    <option>Popular</option>
                    <option>High rating</option>
                    <option>Low rating</option>
                  </select>
                  </>
                  :""

                }
                  
                </div>
              </div>
            </div>
            {

              isLoadingReview? 
              <>
              <div className="mb-4 pb-4 border-bottom">
              <div className="d-flex justify-content-between mb-3">
                <div className="d-flex align-items-center pe-2">
                <img className="rounded-circle me-1" src={owneravatar} width="48" alt="Avatar"/>
                  <div className="ps-2">
                    <h6 className="fs-base mb-0"> <Skeleton style={{height:'15px',width:"100px"}}/></h6><span className="star-rating">
										<Skeleton style={{height:'15px',width:"100px"}}/>
																				                    
                    </span>
                  </div>
                </div><span className="text-muted fs-sm"><Skeleton style={{height:'15px',width:"30px"}}/></span>
              </div>
              <p><Skeleton style={{height:'40px',width:"500px"}}/></p>
           
             
            </div>

            <div className="mb-4 pb-4 border-bottom">
              <div className="d-flex justify-content-between mb-3">
                <div className="d-flex align-items-center pe-2"><img className="rounded-circle me-1" src={owneravatar} width="48" alt="Avatar"/>
                  <div className="ps-2">
                    <h6 className="fs-base mb-0"> <Skeleton style={{height:'15px',width:"100px"}}/></h6><span className="star-rating">
										<Skeleton style={{height:'15px',width:"100px"}}/>
																				                    
                    </span>
                  </div>
                </div><span className="text-muted fs-sm"><Skeleton style={{height:'15px',width:"30px"}}/></span>
              </div>
              <p><Skeleton style={{height:'40px',width:"500px"}}/></p>
           
             
            </div>

            <div className="mb-4 pb-4 border-bottom">
              <div className="d-flex justify-content-between mb-3">
                <div className="d-flex align-items-center pe-2"><img className="rounded-circle me-1" src={owneravatar} width="48" alt="Avatar"/>
                  <div className="ps-2">
                    <h6 className="fs-base mb-0"> <Skeleton style={{height:'15px',width:"100px"}}/></h6><span className="star-rating">
										<Skeleton style={{height:'15px',width:"100px"}}/>
																				                    
                    </span>
                  </div>
                </div><span className="text-muted fs-sm"><Skeleton style={{height:'15px',width:"30px"}}/></span>
              </div>
              <p><Skeleton style={{height:'40px',width:"500px"}}/></p>
           
             
            </div>

            </>
              :


              reviews && reviews.map((review,index)=>{

                const date = formatDate(review.date_created)
                return (

                  <div key={index} className="mb-4 pb-4 border-bottom">
              <div className="d-flex justify-content-between mb-3">
                <div className="d-flex align-items-center pe-2">
                
                <span  style={{width:"50px",height:"60px"}} className="d-inline-block py-1" data-bs-toggle="dropdown">
				{review.user.profile?      
				   <img className="rounded-circle" src={`https://api.shortstaybuddy.com${review.user.profile}`}  style={{width:'100%',height:'100%',objectFit:"cover"}} alt="User"/>
:        <img className="rounded-circle" src={owneravatar} width="40" alt="User"/>
}
</span>
                  <div className="ps-2">
                    <h6 className="fs-base mb-0">{review.user.first_name} {review.user.last_name}</h6><span className="star-rating">
										<i  className={`star-rating-icon  ${review.rating>0?'fi-star-filled text-warning':'fi-star' }`} ></i>
																					<i    className={`star-rating-icon  ${review.rating>1?'fi-star-filled text-warning':'fi-star' }`}  ></i>
																					<i    className={`star-rating-icon  ${review.rating>2?'fi-star-filled text-warning':'fi-star' }`} ></i>
																					<i     className={`star-rating-icon  ${review.rating>3?'fi-star-filled text-warning':'fi-star' }`} ></i>
																					<i     className={`star-rating-icon  ${review.rating>4?'fi-star-filled text-warning':'fi-star' }`} ></i>
																				                    
                    </span>
                  </div>
                </div><span className="text-muted fs-sm">{date}</span>
              </div>
              <p>{review.description}</p>
           
             
            </div>
                )
              })
              
               }

               {
  pageCount<=1?
  ""
  :

           
   
            <nav className="mt-2 mb-4" aria-label="Reviews pagination">
              <ul className="pagination">
                <li className="page-item d-sm-none"><span className="page-link page-link-static">1 / 5</span></li>
                {
                  previousReview?
                  <li className="page-item"><span  onClick={()=>{handlePagination(page -  1)}} className="page-link" href="#" aria-label="Previous"><i className="fi-chevron-left"></i></span></li>
                  :
                  ""


                }
                {
                  page-2>0 && page-2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page - 2)}}>{page-2}</span></li>
                  :""


                }
                {
                 page>0 &&previousReview?

                  <li className="page-item d-none d-sm-block"><span onClick={()=>{handlePagination(page-1)}} className="page-link" href="#">{page-1}</span></li>
                  :
                  ""


                }
                
                {
                  page>0?
                  <li className="page-item active d-none d-sm-block" aria-current="page"><span className="page-link">{page}<span className="visually-hidden">(current)</span></span></li>
                  :''


                }

                {
                  nextReview?
                  <li className="page-item d-none d-sm-block"><span className="page-link" onClick={()=>{handlePagination(page+ 1)}}>{page+1}</span></li>

                  :""

                }
                {
                  page+2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page + 2)}}>{page+2}</span></li>
                  :""


                }

                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block">...</li>
                  
                  :""
                }
                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link"  onClick={()=>{handlePagination(pageCount&&pageCount)}} href="#"> {pageCount&&pageCount}</span></li>
                  
                  :"" 
                }
                {
                  nextReview?
                  <li className="page-item"><span  onClick={()=>{handlePagination(page + 1)}} className="page-link" href="#" aria-label="Next"><i className="fi-chevron-right"></i></span></li>
                  :
                  ""

                }
                
                
              </ul>
            </nav>
               }
          </div>
         
       
         
          <aside className="col-lg-5 col-md-5  pb-1">
            
          {


          token? 
        
            
            
            
            <div className="card shadow-sm mb-4">
					<div className="card-body">
						<div className="d-flex align-items-start justify-content-between">
            
							<a className="text-decoration-none" href="#!">

                <h5 className="mb-1">
                
                <span  style={{width:"50px",height:"60px",marginRight:"4px"}} className="d-inline-block py-1 " data-bs-toggle="dropdown">
				{propertydetail&&propertydetail.user.profile?      
				   <img className="rounded-circle" src={`https://api.shortstaybuddy.com${propertydetail.user.profile}`}  style={{width:'100%',height:'100%',objectFit:"cover"}} alt="User"/>
:        <img className="rounded-circle" src={owneravatar} width="40" alt="User"/>
}
</span>
                {propertydetail&& propertydetail.user.first_name +" "+ propertydetail.user.last_name}
    
    </h5>
								<div className="mb-1">
									
									
									<span className="star-rating">
																								
																				
									</span>
									<span className="ms-1 fs-sm text-muted">
										(0 review's)
									</span>
								</div>
								<p className="text-body">Nothing Here!</p>
							</a>
							<div className="ms-4 flex-shrink-0">
																	<span target="_blank" className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle ms-2 mb-2">
										<i className="fi-chat-circle"></i>
									</span>
																
							</div>
						</div>
						

						
													
														
								<form ref={formref} className="needs-validation" noValidate onSubmit={handleBookProperty} >
									<div className="mb-3">
										<input className="form-control" disabled type="text" value={userdata&&userdata.first_name + ""+userdata.last_name}/>
									</div>
									<div className="mb-3">
										<input className="form-control" disabled  type="email"  value={userdata&&userdata.email}/>
									</div>
									<div className="mb-3">
										<input className="form-control" type="text"  disabled  value={userdata&&userdata.phone_number}/>
									</div>

														<div className="mb-3">
											<label for="check_in">Check In Date</label>
								

                      <Flatpickr
      data-enable-time
      value={bookingData.check_in}
      className="form-control datepicker" 
      placeholder="Choose check in date"
      onChange={([date]) => handleCheckinDateChange(date)}
      options={{
        dateFormat: "Y-m-d H:i",
        minDate: "today",    
       time_24hr:false,
        disable: occupiedDates,
      }}
      required
      
    />

																					</div>

									

										
										<div className="mb-3">
											<label for="check_out">Check Out Date</label>


                      <Flatpickr
                      required
                  
      data-enable-time
      value={bookingData.check_out}
      className="form-control datepicker" 
      placeholder="Choose check out date" 
      onChange={([date]) => handleCheckoutDateChange(date)}
      options={{
        dateFormat: "Y-m-d H:i",
        minDate:addOneDay(bookingData.check_in,propertydetail&&propertydetail.pricing[0].max_days_allowed),
        disable: occupiedDates,
       
        time_24hr:false
      }}
      
    />
																					</div>

                                          <div className="mb-3">
											<label for="how_long">Duration (days)</label>
											<input type="number" disabled required ref={durationRef} className="form-control datepicker" title={`How long are you staying in days`}   placeholder={`Choose duration `}
                      
                      value={duration.days>0?duration.days:'' }
                         step="any" min={propertydetail&&propertydetail.pricing[0].max_days_allowed}  />


																					</div>


										
									
									<div className="mb-3">
										<label for="no_of_person">Number of person's</label>
										<input  ref={totalpeopleRef} onChange={handletotalcalculate} className="form-control"  required type="number" min="1"  placeholder="How many persons will be checking in?"/>

																			</div>

{
  showtotal?
  <div className="alert alert-secondary " role="alert">
  <div className='W-full d-flex justify-content-between  align-items-stretch '>


<div className='text-decoration-underline' >Total Fee</div> <div  >{propertydetail&&propertydetail.pricing[0].currency} {total}</div>


  </div>
</div>
:
""
					

}
																			
 														
									
									{
                    isLoadingBooking?
                    <button className="btn btn-lg btn-primary d-block w-100" type="submit">
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Submitting</button>
                    :

                    <button className="btn btn-lg btn-primary d-block w-100" type="submit">Make Enquiry</button>

                  }
									

									
								</form>
																			
					</div>
				</div>

            
            
            
            :



          

          <div className="card-body">
						<div className="d-flex align-items-start justify-content-between">
							<a className="text-decoration-none" href="#!">
<img className="rounded-circle mb-2" src={owneravatar} width="60" alt="Avatar" />
								<h5 className="mb-1">{propertydetail&& propertydetail.user.first_name +" "+ propertydetail.user.last_name}</h5>
								<div className="mb-1">
									
									
									<span className="star-rating">
																															<i className="star-rating-icon fi-star"></i>
																					<i className="star-rating-icon fi-star"></i>
																					<i className="star-rating-icon fi-star"></i>
																					<i className="star-rating-icon fi-star"></i>
																					<i className="star-rating-icon fi-star"></i>
																				
									</span>
									<span className="ms-1 fs-sm text-muted">
										(0 review's)
									</span>
								</div>
								<p className="text-body">Nothing Here!</p>
							</a>
							<div className="ms-4 flex-shrink-0">
																	<span className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle ms-2 mb-2">
										<i className="fi-chat-circle"></i>
									</span>
																
							</div>
						</div>
						

						
													<div className="text-center">
								<span  onClick={toggleModal} className="btn d-block btn-primary">Login/Register to Continue</span>
							</div>
												
					</div>
          }
            
           
            <div className="pt-2">
              <div className="position-relative mb-2"><img className="rounded-3" src={map} alt="Map"/>
                <div className="d-flex w-100 h-100 align-items-center justify-content-center position-absolute top-0 start-0">
                {
                  token?                <a className="btn btn-primary stretched-link"  href={propertydetail&&propertydetail.map_link} target='_blank' ><i className="fi-route me-2"></i>Get directions</a>
                  :
                  <span  className="btn btn-primary stretched-link"  onClick={toggleModal} ><i className="fi-route me-2"></i>Get directions</span>


                }
                
                </div>
              </div>
            
            </div>
          </aside>
        </div>
      </section>

      <SigninModal changeshow={toggleModal}  show={showmodal} backdrop={showbackdrop} />

      </div>



  )
}

export default PropertyDetailPage