import { apiSlice } from "../../app/api/apiSlice";


export const partnersApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        partnerDashboard:builder.mutation({
            query:()=>({
                url:'/partner/dashboard/ ',
                method: 'GET',

            })
        }),   
        userReceipts:builder.mutation({
            query:()=>({
                url:'/transaction/partner-receipts/',
                method: 'GET',

            })
        }),  
        partnercount:builder.mutation({
            query:()=>({
                url:'/transaction/partner-count/',
                method: 'GET',

            })
        }), 
        userPromocode:builder.mutation({
            query:(params)=>({
                url:'/admin/user/promocode/',
                method: 'GET',
                params:params

            })
        }),  
         

        

    



    })


})
  



export const {
usePartnerDashboardMutation,
useUserReceiptsMutation,
useUserPromocodeMutation,
usePartnercountMutation

}=partnersApiSlice;




















