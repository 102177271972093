import React, { useEffect, useState,useRef } from 'react'

import map from '../../asserts/img/real-estate/single/map.jpg'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGetMyPropertyDetailMutation ,useAddReviewMutation ,useGetReviewsMutation,} from '../../features/property/propertySlice'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../features/auth/authSlice'
import { selectUserData} from '../../features/auth/authSlice'
import owneravatar from '../../asserts/img/avatars/14.png'
import { useParams } from 'react-router-dom'
import "../../asserts/css/theme.min.css"


function MyPropertyDetail() {

  const [getpropertydetail,{isLoading}]=useGetMyPropertyDetailMutation()
  const [propertydetail,setPropertyDetail]=useState()
  const [showmodal,setshowmodal]=useState(false)
  const [showbackdrop,setshowbackdrop]=useState(false)
  const [ratingnumber,setRatingNumber]=useState(0)
  const [addreview,{isLoading:isAddingReview}]=useAddReviewMutation()
  const [getreviews,{isLoading:isLoadingReview}]=useGetReviewsMutation()
  const [reviewscount,setReviewscount]=useState(0)
  const [imagelength,setImageLength]=useState()

  const [reviews,setReviews]=useState()
  const descriptionref=useRef()
  const  token =useSelector(selectCurrentToken)
  const userdata=useSelector(selectUserData)
  const [reviewcount,setReviewCount]=useState(0)
  const [showmore,setShowMore]=useState(false)
  const toggleModal = () => {
    setshowmodal(!showmodal)
    setshowbackdrop(!showbackdrop)
  };
  
  const {id}=useParams()
  const handleGetPropertyDetail=async()=>{
    const property_id=id;
    const response=await getpropertydetail(property_id);
    console.log(response.data);
    setPropertyDetail(response.data)
    setImageLength(response.data.other_files.slice(2,7).length)



    
  }

 

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const [nextReview,setNextReview]=useState()
  const [previousReview,setPreviousReview]=useState()
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState()



  const handleGetReviews=async(pageparam)=>{
    try {
      const param=
      {
        page:pageparam,
        property_id:id
      }
      
      
      const response = await getreviews(param);
      console.log('reviews',response)
      setReviews(response.data.results)
      console.log("next" ,response.data.next)
      setNextReview(response.data.next)
      setPreviousReview(response.data.previous)
      setReviewscount(response.data.count)
      setPageCount(0)
      setPageCount(Math.ceil(response.data.count / 10))
      console.log("page count: " ,pageCount)


    }catch(err){
      console.log(err)
    }
  }


  const handlePagination=(page)=>{
    setPage(page);
    handleGetReviews(page);
  }


  useEffect(()=>{
    handleGetPropertyDetail();
    handleGetReviews()
 
  },[])


  
  return (
    <div>
    <section className="container pt-5 mt-5">
  
        <nav className="mb-3 pt-md-3" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="real-estate-home-v1.html">Home</a></li>
            <li className="breadcrumb-item"><a href="real-estate-catalog-rent.html">My Properties</a></li>
            <li className="breadcrumb-item active" aria-current="page">
            {
          isLoading? <Skeleton style={{width:"100px",height:"8px"}}/>:
          propertydetail && propertydetail.title
        }
            </li>
          </ol>
        </nav>
        <h1 className="h2 mb-2">
        {
          isLoading? <Skeleton style={{width:"200px",height:"20px"}}/>:
          propertydetail && propertydetail.title
        }
        </h1>
       
        
        <div className="d-flex justify-content-between align-items-center">
          <ul className="d-flex mb-4 list-unstyled">
            <li className="me-3 pe-3 border-end d-flex justify-content-center align-items-center">
            
            <b className="me-1" style={{marginBottom:"4px"}}>   {
          isLoading? <Skeleton style={{width:"10px",height:"10px"}}/>:
          propertydetail && <span  > {propertydetail.bedroom}</span>
        }</b><i className="fi-bed mt-n1   lead align-middle text-muted"></i>
        
        </li>
            <li className="me-3 pe-3 border-end">
              <b className="me-1" style={{marginBottom:"4px"}}>   {
          isLoading? <Skeleton style={{width:"10px",height:"10px"}}/>:
          propertydetail && <span  > {propertydetail.bathroom}</span>
        }</b><i className="fi-bath mt-n1 lead align-middle text-muted"></i></li>
            <li className="me-3 pe-3 border-end">
            <b className="me-1" style={{marginBottom:"4px"}}>   {
          isLoading? <Skeleton style={{width:"10px",height:"10px"}}/>:
          propertydetail && <span  > {propertydetail.parking_spot}</span>
        }</b><i className="fi-car mt-n1 lead align-middle text-muted"></i></li>
            
          </ul>
          <div className="text-nowrap">
            <div className="dropdown d-inline-block" data-bs-toggle="tooltip" title="Share">
              <button className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle ms-2 mb-2" type="button" data-bs-toggle="dropdown"><i className="fi-share"></i></button>
              <div className="dropdown-menu dropdown-menu-end my-1">
                <button className="dropdown-item" type="button"><i className="fi-facebook fs-base opacity-75 me-2"></i>Facebook</button>
                <button className="dropdown-item" type="button"><i className="fi-twitter fs-base opacity-75 me-2"></i>Twitter</button>
                <button className="dropdown-item" type="button"><i className="fi-instagram fs-base opacity-75 me-2"></i>Instagram</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {
        isLoading?
       
        <section className="container overflow-auto mb-4 pb-3">
        <PhotoProvider
        speed={() => 800}
  easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
      >
        <div className="row g-2 g-md-3 gallery" data-thumbnails="true" style={{ minWidth: '30rem' }}>
          <div className="col-8">
           
            <div >
                <div  className="gallery-item rounded rounded-md-3" >
              <Skeleton 
                style={{ height: '600px', objectFit: 'cover' }} 
                className="rounded rounded-md-3" />
              </div>
              </div >
           
          </div>
          <div className="col-4">
          <div className="gallery-item rounded rounded-md-3 mb-2 mb-md-3" >
              <Skeleton style={{height: '290px'}}/>
            </div>
            
            
           
                  
                           <div className="gallery-item rounded rounded-md-3 mb-2 mb-md-3" >
              <Skeleton style={{height: '290px'}}/>
            </div>
            
            
            
            </div>
          
          <div className="col-12">
            <div className=" row row-cols-4  g-2 g-md-3">
        
          
          
               
            <div className="col">          
             <div
              className={`gallery-item rounded rounded-md-3 mb-2 mb-md-3`} >
              <Skeleton style={{height: '200px'}}/>
                              </div>
            
            </div>
            
            <div className="col">          
             <div
              className={`gallery-item rounded rounded-md-3 mb-2 mb-md-3`} >
              <Skeleton style={{height: '200px'}}/>
                              </div>
            
            </div>
            
            <div className="col">          
             <div
              className={`gallery-item rounded rounded-md-3 mb-2 mb-md-3`} >
              <Skeleton style={{height: '200px'}}/>
                              </div>
            
            </div>
            
            <div className="col">          
             <div
              className={`gallery-item rounded rounded-md-3 mb-2 mb-md-3`} >
              <Skeleton style={{height: '200px'}}/>
                              </div>
            
            </div>
      
              
              </div>

            </div>
          
        </div>
        </PhotoProvider>
     
    </section>
        :
        propertydetail&&
        <section className="container overflow-auto mb-4 pb-3">
        <PhotoProvider
        speed={() => 800}
  easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
      >
        <div className="row g-2 g-md-3 gallery" data-thumbnails="true" style={{ minWidth: '30rem' }}>
          <div className="col-8">
           
            <PhotoView     
             src={`https://api.shortstaybuddy.com${propertydetail.files}`}>
                <div  className="gallery-item rounded rounded-md-3" >
              <img 
                style={{ height: '600px', objectFit: 'cover' }} 
                className="rounded rounded-md-3" 
                src={`https://api.shortstaybuddy.com${propertydetail.files}`}
                      alt="Gallery thumbnail" 
              />
              </div>
              </PhotoView>
           
          </div>
          <div className="col-4">
          {
            
            propertydetail && propertydetail.other_files[0]&&
            <PhotoView     
             src={`https://api.shortstaybuddy.com${propertydetail.other_files[0].files}`}>
             <div
              className="gallery-item rounded rounded-md-3 mb-2 mb-md-3" 
             
            >
              <img 
                style={{ height: '290px', objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com${propertydetail.other_files[0].files}`}
                alt="Gallery thumbnail" 
              />
            </div>
            </PhotoView>
          }
            
            {
              propertydetail && propertydetail.other_files[1]&& 
              <PhotoView     
             src={`https://api.shortstaybuddy.com${propertydetail.other_files[1].files}`}>
             <div
              className="gallery-item rounded rounded-md-3 mb-2 mb-md-3" 
             
            >
              <img 
                style={{ height: '290px', objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com${propertydetail.other_files[1].files}`}
                alt="Gallery thumbnail" 
              />
            </div>
            </PhotoView>
            }
           
          </div>
          <div className="col-12">
            <div className=" row row-cols-4  g-2 g-md-3">
            {
            propertydetail && 
        propertydetail.other_files[2]&&
        propertydetail.other_files.slice(2,7).map((file,index)=>{

          return (
          

            <>
            <div className="col">

            {
              index>3?
              <PhotoView     
             src={`https://api.shortstaybuddy.com${file.files}`}>
             <div
              className={`gallery-item ${index >=3 &&imagelength>4?"more-item ":""} rounded rounded-md-3 mb-2 mb-md-3`} >
              <img 
                style={{ height: '200px',display:"none", objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com${file.files}`}
                alt="Gallery thumbnail" 
              />
                           

            </div>
            
            </PhotoView>
             :
             
              <PhotoView     
             src={`https://api.shortstaybuddy.com${file.files}`}>
             <div
              className={`gallery-item ${index >=3 &&imagelength>4?"more-item ":""} rounded rounded-md-3 mb-2 mb-md-3`} >
              <img 
                style={{ height: '200px', objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com${file.files}`}
                alt="Gallery thumbnail" 
              />
                           

              
              {
                index >=3 &&imagelength>4?
                <span class="gallery-item-caption fs-base" style={{cursor:"pointer"}}>
                
                +{imagelength-4} <span class='d-none d-md-inline'>photos</span>
                
                </span>
                :""
              }
            </div>
            
            </PhotoView>
            }
               
      
              
              </div>

            </>
         ) })
          
          }
         
            </div>
          </div>
        </div>
        </PhotoProvider>
     
    </section>
      }
      <section className="container mb-5 pb-1">
        <div className="row">
        <div className="col-md-7 mb-md-0 mb-4">

          <h4 class="h5 mb-0"><span class="fs-4">{ propertydetail&&propertydetail.pricing[0].currency +" "+  propertydetail.pricing[0].price}&nbsp;</span><span class="fs-base fw-normal text-body">/day</span></h4>
          <span className="fs-base fw-normal text-body">Min days /{propertydetail&&propertydetail.pricing[0].max_days_allowed} days</span>


     

          <h2 class="h3 mb-4 pb-4 border-bottom">
			
					
				
				</h2>

            <div className="mb-4 pb-md-3">
              <h3 className="h4">Overview</h3>
              <p className="mb-1 "  style={{wordWrap:'break-word'}}>

                {
                  isLoading?<Skeleton style={{width: '300px', height: "200px"}} />:
                  propertydetail&&propertydetail.description.length >300?
                  <>
                  {propertydetail&&propertydetail.description.slice(0,300)}<br></br>
                  {
                    showmore?"":
                    <a class="collapse-label collapsed" onClick={()=>{setShowMore(true)}} style={{cursor:"pointer"}}>show more</a>

                  }

                  <div class={`collapse ${showmore?"show":""}`} id="seeMoreOverview" >
                <p class="mb-1">
                {propertydetail&&propertydetail.description.slice(300,propertydetail.description.length)}
                <br>

                </br>
                    <a class="collapse-label collapsed" onClick={()=>{setShowMore(false)}} style={{cursor:"pointer"}}>show less</a>

                </p>
              </div>
                  </>
    :
    propertydetail&&propertydetail.description
                }
              </p>
       
            </div>
            
            <div className="mb-4 pb-md-3">
              <h3 className="h4">Property Details</h3>
              <ul className="list-unstyled mb-0">
                      <li className="d-flex"><b>Category: </b>
          {
            isLoading ?
            <Skeleton style={{width: '200px', height: "15px", marginLeft: "5px"}} /> :
            propertydetail && propertydetail.category.map((category, index, arr) => {
              // Check if it's the last element
              const separator = index === arr.length - 1 ? '.' : ', ';
              return category.name + separator;
            })
          }
        </li>

                
                <li><b>Bedrooms: </b>{propertydetail&&propertydetail.bedroom}</li>
                <li><b>Bathrooms: </b>{propertydetail&&propertydetail.bathroom}</li>
              <li><b>Parking places: </b>{propertydetail&&propertydetail.parking_spot}</li>
              <li><b>Max people allowed: </b> {propertydetail&&propertydetail.mi}</li>

              
              <li><b>Parties allowed: </b> {propertydetail&&propertydetail.is_party_inclusive==="true"?"Yes":"No"}</li>


                <li><b>Privilege:</b>{propertydetail && propertydetail.privilege.map((privilege, index, arr) => {
              // Check if it's the last element
              const separator = index === arr.length - 1 ? '.' : ', ';
              return privilege.name +" allowed"+ separator;
            })
          }</li>
              </ul>
            </div>
        

        
            <div className="mb-4 pb-md-3">
              <h3 className="h4">Amenities</h3>
              <ul className="list-unstyled row row-cols-lg-3 row-cols-md-2 row-cols-1 gy-1 mb-1 text-nowrap">
              {
                propertydetail && propertydetail.amenity.map((amenity,e)=>{
                  return (
                    <li className="col"><i className="fi-snowflake mt-n1 me-2 fs-lg align-middle" key={e} ></i>{amenity.name}</li>


                  )
                })
              }
               
              </ul>
            </div>

            {

isLoadingReview? 
<>
<div class="mb-4 pb-4 border-bottom">
<div class="d-flex justify-content-between mb-3">
  <div class="d-flex align-items-center pe-2">
  <img class="rounded-circle me-1" src={owneravatar} width="48" alt="Avatar"/>
    <div class="ps-2">
      <h6 class="fs-base mb-0"> <Skeleton style={{height:'15px',width:"100px"}}/></h6><span class="star-rating">
      <Skeleton style={{height:'15px',width:"100px"}}/>
                                              
      </span>
    </div>
  </div><span class="text-muted fs-sm"><Skeleton style={{height:'15px',width:"30px"}}/></span>
</div>
<p><Skeleton style={{height:'40px',width:"500px"}}/></p>


</div>

<div class="mb-4 pb-4 border-bottom">
<div class="d-flex justify-content-between mb-3">
  <div class="d-flex align-items-center pe-2"><img class="rounded-circle me-1" src={owneravatar} width="48" alt="Avatar"/>
    <div class="ps-2">
      <h6 class="fs-base mb-0"> <Skeleton style={{height:'15px',width:"100px"}}/></h6><span class="star-rating">
      <Skeleton style={{height:'15px',width:"100px"}}/>
                                              
      </span>
    </div>
  </div><span class="text-muted fs-sm"><Skeleton style={{height:'15px',width:"30px"}}/></span>
</div>
<p><Skeleton style={{height:'40px',width:"500px"}}/></p>


</div>

<div class="mb-4 pb-4 border-bottom">
<div class="d-flex justify-content-between mb-3">
  <div class="d-flex align-items-center pe-2"><img class="rounded-circle me-1" src={owneravatar} width="48" alt="Avatar"/>
    <div class="ps-2">
      <h6 class="fs-base mb-0"> <Skeleton style={{height:'15px',width:"100px"}}/></h6><span class="star-rating">
      <Skeleton style={{height:'15px',width:"100px"}}/>
                                              
      </span>
    </div>
  </div><span class="text-muted fs-sm"><Skeleton style={{height:'15px',width:"30px"}}/></span>
</div>
<p><Skeleton style={{height:'40px',width:"500px"}}/></p>


</div>

</>
:


reviews && reviews.map((review,index)=>{

  const date = formatDate(review.date_created)
  return (

    <div key={index} class="mb-4 pb-4 border-bottom">
<div class="d-flex justify-content-between mb-3">
  <div class="d-flex align-items-center pe-2">
  
  <span  style={{width:"50px",height:"60px"}} className="d-inline-block py-1" data-bs-toggle="dropdown">
{review.user.profile?      
<img className="rounded-circle" src={`https://api.shortstaybuddy.com${review.user.profile}`}  style={{width:'100%',height:'100%',objectFit:"cover"}} alt="User"/>
:        <img className="rounded-circle" src={owneravatar} width="40" alt="User"/>
}
</span>
    <div class="ps-2">
      <h6 class="fs-base mb-0">{review.user.first_name} {review.user.last_name}</h6><span class="star-rating">
      <i  class={`star-rating-icon  ${review.rating>0?'fi-star-filled text-warning':'fi-star' }`} ></i>
                            <i    class={`star-rating-icon  ${review.rating>1?'fi-star-filled text-warning':'fi-star' }`}  ></i>
                            <i    class={`star-rating-icon  ${review.rating>2?'fi-star-filled text-warning':'fi-star' }`} ></i>
                            <i     class={`star-rating-icon  ${review.rating>3?'fi-star-filled text-warning':'fi-star' }`} ></i>
                            <i     class={`star-rating-icon  ${review.rating>4?'fi-star-filled text-warning':'fi-star' }`} ></i>
                                              
      </span>
    </div>
  </div><span class="text-muted fs-sm">{date}</span>
</div>
<p>{review.description}</p>


</div>
  )
})

 }

 {
pageCount<=1?
""
:



<nav className="mt-2 mb-4" aria-label="Reviews pagination">
<ul className="pagination">
  <li className="page-item d-sm-none"><span className="page-link page-link-static">1 / 5</span></li>
  {
    previousReview?
    <li className="page-item"><span  onClick={()=>{handlePagination(page -  1)}} className="page-link" href="#" aria-label="Previous"><i className="fi-chevron-left"></i></span></li>
    :
    ""


  }
  {
    page-2>0 && page-2<=pageCount?
    <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page - 2)}}>{page-2}</span></li>
    :""


  }
  {
   page>0 &&previousReview?

    <li className="page-item d-none d-sm-block"><span onClick={()=>{handlePagination(page-1)}} className="page-link" href="#">{page-1}</span></li>
    :
    ""


  }
  
  {
    page>0?
    <li className="page-item active d-none d-sm-block" aria-current="page"><span className="page-link">{page}<span className="visually-hidden">(current)</span></span></li>
    :''


  }

  {
    nextReview?
    <li className="page-item d-none d-sm-block"><span className="page-link" onClick={()=>{handlePagination(page+ 1)}}>{page+1}</span></li>

    :""

  }
  {
    page+2<=pageCount?
    <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page + 2)}}>{page+2}</span></li>
    :""


  }

  {
    page+4<=pageCount?
    <li className="page-item d-none d-sm-block">...</li>
    
    :""
  }
  {
    page+4<=pageCount?
    <li className="page-item d-none d-sm-block"><span className="page-link"  onClick={()=>{handlePagination(pageCount&&pageCount)}} href="#"> {pageCount&&pageCount}</span></li>
    
    :"" 
  }
  {
    nextReview?
    <li className="page-item"><span  onClick={()=>{handlePagination(page + 1)}} className="page-link" href="#" aria-label="Next"><i className="fi-chevron-right"></i></span></li>
    :
    ""

  }
  
  
</ul>
</nav>
 }


            
            <div className="mb-lg-5 mb-md-4 pb-lg-2 py-4 border-top">
              <ul className="d-flex mb-4 list-unstyled fs-sm">
                
               
              </ul>
            </div>
           

      



           
           
        

           
   
       
          </div>
          <aside className="col-lg-5 col-md-6 ms-lg-auto pb-1">
            
            <div >
              <div className="card-body">
                <div className="d-flex align-items-start justify-content-between">
                <a class="text-decoration-none" href="#!">

<h5 className="mb-1">

<span  style={{width:"50px",height:"60px",marginRight:"4px"}} className="d-inline-block py-1 " data-bs-toggle="dropdown">
{propertydetail&&propertydetail.user.profile?      
<img className="rounded-circle" src={`https://api.shortstaybuddy.com${propertydetail.user.profile}`}  style={{width:'100%',height:'100%',objectFit:"cover"}} alt="User"/>
:        <img className="rounded-circle" src={owneravatar} width="40" alt="User"/>
}
</span>
{propertydetail&& propertydetail.user.first_name +" "+ propertydetail.user.last_name}

</h5>
<div class="mb-1">
  
  
<span className="mb-1">
                    </span>
  <span class="ms-1 fs-sm text-muted">
  (<span className="ms-1 fs-sm text-muted">{ `${reviewcount}`}</span> review's)
  </span>
</div>

</a>
                    
              </div>
               
               
             
              </div>
            </div>
           
            <div className="pt-2">
              <div className="position-relative mb-2"><img className="rounded-3" src={map} alt="Map"/>
                <div className="d-flex w-100 h-100 align-items-center justify-content-center position-absolute top-0 start-0"><a className="btn btn-primary stretched-link" href={propertydetail&&propertydetail.map_link} target='_blank' ><i className="fi-route me-2"></i>Get directions</a></div>
              </div>
            
            </div>
          </aside>
        </div>
      </section>


      </div>
  )
}

export default MyPropertyDetail